import { $http } from '@/services/http';
import { i18n } from '@/plugins/i18n';
import { get } from 'lodash';
import { tagSubtitution } from '@/services/ContentService';
import { splitColorsRGBA, chooseTextColor, colorDarken, chooseButtonIconColor, splitColorsRGB } from '@/services/ColorService';
import * as queryString from 'querystring';
import { getFont } from '@/services/FontService';
import router from '@/router/index.js';
import {
    ROUTE_REGISTRATION,
    ROUTE_DYNAMIC_EVENT_PAGE,
} from '@/router/constants';


const projectStore = {
    namespaced: true,
    state: {
        project: null,
        eventCountMap: {},
        smallDevice: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent),
    },
    getters: {
        isDynamicBlocksFlavourAgenda() {
            return [ROUTE_DYNAMIC_EVENT_PAGE, ROUTE_REGISTRATION].indexOf(router.currentRoute.name) > -1;
        },
        registrationIntroductionText: state => state.project.registration.introductionText,
        registrationConfirmationText: state => state.project.registration.confirmationText,
        onSiteRegistrationConfirmationText: state => state.project.registration.onSiteConfirmationText,
        pwaHomescreenContent: state => state.project.pwaHomescreenContent,
        registrationType: state => get(state, 'project.registration.type'),
        isOpenRegistrationType: state => state.project.registration.type === 'open',
        registrationFields: state => state.project.registration.fields,
        logo: state => state.project.style.logoUrl,
        primaryColor: state => get(state, 'project.style.primaryColor', 'rgba(240, 240, 240, 1)'),
        backgroundGradient: state => get(state, 'project.style.backgroundGradient', true),
        pwaBackgroundGradient: state => {
            return state.project.pwaStyle.backgroundGradient !== 'undefined' ? state.project.pwaStyle.backgroundGradient : true;
        },
        currentEvent: state => state.project.event,
        events: state => state.project.events,
        backgroundImage: state => state.project.style?.backgroundUrl || '/img/background-quadia-live.jpg' ,
        pwaBackgroundImage: state => state.project.pwaStyle?.backgroundUrl || '/img/background-quadia-live.jpg',
        springerLook: (state, getters) => getters.registrationType === 'springer',
        hasOnDemand: state => get(state, 'project.event.onDemandID', null) !== null && get(state, 'project.event.onDemandActive', false),
        privacyPolicyUrl: state => state.project.privacyPolicyUrl || '#privacy-policy',
        getContent: (state, getters, rootState, rootGetters) => (text) => {
            let content = text;
            if (text) {
                const onDemandEvents = (!getters.requiresRegistrationForOnDemand && !getters.isOpenRegistrationType)
                    ? state.project.events.filter(e => e.onDemandActive).map(e => e._id)
                    : undefined;
                const registrationId = getters.isOpenRegistrationType
                    ? 'gm84cf8a74'
                    : rootGetters['registration/registrationId'];
                const currentEvent = getters.currentEvent;
                const privacyPolicyUrl = getters.privacyPolicyUrl;
                const eventTitle = state.project.eventTitle;
                content = tagSubtitution(text, currentEvent, registrationId, eventTitle, privacyPolicyUrl, onDemandEvents);
            }
            return content;
        },
        showPollsOverVideo: state => state.project.showPollsOverVideo,
        showQuizOverVideo: state => state.project.showQuizOverVideo,
        showProgramTab: state => state.project.showProgramTab,
        isMultiSession: state => state.project?.projectType === 'multiSession',
        isHybrid: state => state.project?.projectType === 'hybrid',
        showCustomTab: state => state.project.customTab?.show || false,
        customTabTitle: state => state.project.customTab?.title || '',
        customTabIcon: state => state.project.customTab?.icon || '',
        customTabBodyText: state => state.project.customTab?.bodyText || '',
        navigateToElearningCourseButtonHref: state => state.project.elearningButton.href || '',
        navigateToElearningCourseButtonTitle: state => state.project.elearningButton.title || '',
        overwriteEvaluationButton: state => state.project.evaluationButton.overwrite || false,
        evaluationButtonTitle: state => state.project.evaluationButton.title || '',
        evaluationButtonHref: state => state.project.evaluationButton.href || '',
        isInPreviewMode: () => window.location.search.indexOf('previewMode') >= 0,
        registrationConfirmationPreviewMode: () => {
            const c = queryString.parse(window.location.search.substring(1));
            return c.previewMode === 'on-site';
        },
        chatType: state => state.project.event.chatType,
        speakers: state => state.project?.speakers || [],
        hasSpeakers: state => (state.project?.speakers || []).length > 0,
        hideCalendarInvite: state => state.project?.hideCalendarInvite || false,
        primaryColorRGB: (state, getters) => splitColorsRGBA(getters.primaryColor),
        pwaPrimaryColor: state => state.project.pwaStyle.primaryColor,
        pwaPrimaryColorRGB: state => splitColorsRGBA(state.project.pwaStyle.primaryColor),
        secondaryColor: state => state.project.pwaStyle.secondaryColor,
        secondaryColorRGB: state => splitColorsRGBA(state.project.pwaStyle.secondaryColor),
        secondaryColorStyle: (state) => {
            return { '--secondary-color': state.project.pwaStyle.secondaryColor };
        },
        pwaDarkenPrimaryColor: (state, getters) => {
            const { r, g, b } = getters.pwaPrimaryColorRGB;
            return colorDarken(r, g, b);
        },
        darkenPrimaryColor: (state, getters) => {
            const { r, g, b } = getters.primaryColorRGB;
            return colorDarken(r, g, b);
        },
        darkenPrimaryColorRGB: (state, getters) => {
            return splitColorsRGB(getters.darkenPrimaryColor);
        },
        pwaDarkenPrimaryColorRGB: (state, getters) => {
            return splitColorsRGB(getters.darkenPrimaryColor);
        },
        darkenPrimaryColorStyle: (state, getters) => {
            return {
                '--darken-primary-color': getters.darkenPrimaryColor,
            };
        },
        pwaDarkenPrimaryColorStyle: (state, getters) => {
            return {
                '--darken-primary-color': getters.pwaDarkenPrimaryColor,
            };
        },
        textColorOnDarkenPrimaryColor: (state, getters) => {
            const { r, g, b } = splitColorsRGB(getters.pwaDarkenPrimaryColor);
            return {
                '--text-color-on-darken': chooseTextColor(r, g, b)
            };
        },
        textColorStyle: (state, getters) => {
            const { r, g, b } = getters.darkenPrimaryColorRGB;
            return {
                '--text-color': chooseTextColor(r, g, b)
            };
        },
        pwaTextColorStyle: (state, getters) => {
            const { r, g, b } = splitColorsRGBA(getters.primaryColor);
            return {
                '--text-color': chooseTextColor(r, g, b)
            };
        },

        buttonShadowColorStyle: (state, getters) => {
            return {
                '--button-shadow-color': `rgba(${getters.darkenPrimaryColorRGB.r},${getters.darkenPrimaryColorRGB.g},${getters.darkenPrimaryColorRGB.b}, 0.3)`,
            };
        },
        fontFamilyStyle: (state) => {
            let fontFamily = getFont(state.project.style?.fontFamily || 'archivo');
            return {
                '--font-family': fontFamily,
            };
        },
        buttonBorderRadiusStyle: (state) => {
            let value;
            if(state.project.style.buttonBorderRadius === null){
                value = '4px';
            }else{
                value = state.project.style.buttonBorderRadius + 'px';
            }
            return {
                '--button-border-radius': value,
            };
        },
        labelBorderRadiusStyle: (state) => {
            let value;
            if(state.project.style.labelBorderRadius === null){
                value = '20px';
            }else{
                value = state.project.style.labelBorderRadius + 'px';
            }
            return {
                '--label-border-radius': value,
            };
        },
        cardBorderRadiusStyle: (state) => {
            let value;
            if(state.project.style.cardBorderRadius === null){
                value = '4px';
            }else{
                value = state.project.style.cardBorderRadius + 'px';
            }
            return {
                '--card-border-radius': value,
            };
        },
        inputBorderRadiusStyle: (state) => {
            let value;
            if(state.project.style.inputBorderRadius === null){
                value = '0px';
            }else{
                value = state.project.style.inputBorderRadius + 'px';
            }
            return {
                '--input-border-radius': value,
            };
        },
        buttonBackgroundStyle: (state, getters) => {
            return {
                '--button-background' : getters.darkenPrimaryColor
            };
        },
        secondaryTextColor: (state, getters) => {
            const { r, g, b } = getters.secondaryColorRGB;
            return chooseButtonIconColor(r, g, b);
        },
        preventSwitchingRooms: state => state.project?.preventSwitchingRooms || false,
        topics: state => state.project?.topics || [],
        expectedOnSiteViewers: state => state.project.expectedOnSiteViewers ?? 0,
        calendarTitle: state => state.project.calendarTitle ?? '',
        requiresRegistrationForOnDemand: state => state.project.requireRegistrationForOnDemand ?? false,
        shouldAttachRegistrationDataToAskAQuestionComponent: state => state.project.registration.attachRegistrationDataToAskAQuestion,
        customCtaButtonEnabled: state => state.project.customCtaButton.enabled,
        customCtaButtonTitle: state => state.project.customCtaButton.title,
        customCtaButtonHref: state => state.project.customCtaButton.href,
        faviconUrl: state => state.project.style.faviconUrl,
        springerOAuthClientId: (state) => {
            if (state.project.companyId === process.env.VUE_APP_SPRINGER_INTERNATIONAL_SMEDCOM_COMPANY_ID) {
                return process.env.VUE_APP_SPRINGER_INTERNATIONAL_SMEDCOM_OAUTH_CLIENT_ID;
            }
            return process.env.VUE_APP_SPRINGER_OAUTH_CLIENT_ID;
        },
    },
    mutations: {
        setProject: (state, payload) => state.project = payload,
        setSecureData: (state, payload) => {
            state.project.event.liveDirectorBroadcastSlug = payload.liveDirectorBroadcastSlug;
            state.project.event.onDemandID = payload.onDemandID;
        },
        setRegistrationsEventCountMap: (state, payload) => state.eventCountMap = payload,
    },
    actions: {
        getProject: async ({ commit, rootGetters }, payload) => {
            const url = process.env.VUE_APP_EVENT_ENDPOINT.replace('{eventID}', payload)
                + `?timeZone=${rootGetters['registration/clientTimeZone']}`;
            let response = await $http.get(url);
            i18n.locale = response.data.language;
            commit('setProject', response.data);
        },
        getDefaultProjectByCompanyId: async ({ commit }, payload) => {
            let url = process.env.VUE_APP_COMPANY_PREVIEW_ENDPOINT.replace('{companyId}', payload);
            let response = await $http.get(url);
            commit('setProject', response.data);
        },
        getRegistrationsCountPerEvent: async ({ commit, rootState }) => {
            const projectId = rootState.project.project?._id;
            if (!projectId) {
                throw new Error('Invalid project ID!');
            }
            const url = process.env.VUE_APP_REQUEST_REGISTRATION_EVENT_COUNT_ENDPOINT.replace('{projectID}', projectId);
            const map = {};
            let response = await $http.get(url);
            response.data.forEach((item) => {
                map[item.event] = item.regsCount;
            });
            commit('setRegistrationsEventCountMap', map);
        },
        registerAction: async ({ state, getters, rootGetters }, payload) => {
            const isQuadiaAdminVisiting = localStorage.getItem('ignoreStatActions') === '1';
            if (getters.isInPreviewMode || isQuadiaAdminVisiting) {
                return;
            }
            let url = process.env.VUE_APP_QLIVE_ANALYTICS_PAGE_VIEWS_ENDPOINT.replace('{projectId}', state.project._id);
            if (rootGetters['registration/isRegisteredForPwa']) {
                url += '-pwa';
            }
            const registrationId = rootGetters['registration/registrationId'];
            let data = {
                action: payload
            };

            if (registrationId) {
                data = { ...data, registrationId };
            }
            await $http.post(url, data);
        },
        registerUserLinkClick: async ({state, getters, rootGetters}, payload) => {
            const isQuadiaAdminVisiting = localStorage.getItem('ignoreStatActions') === '1';
            if (getters.isInPreviewMode || isQuadiaAdminVisiting) {
                return;
            }
            let url = process.env.VUE_APP_QLIVE_ANALYTICS_LINK_CLICKS_ENDPOINT.replace('{projectId}', state.project._id);
            if (rootGetters['registration/isRegisteredForPwa']) {
                url += '-pwa';
            }
            let data = {...payload, eventId: getters['currentEvent']._id};
            const registrationId = rootGetters['registration/registrationId'];
            if (registrationId) {
                data = {...data, registrationId};
            }
            await $http.post(url, data);
        }
    }
};

export default projectStore;
