<template>
    <v-tabs-items v-model="tab"
                  :style="cssVars"
    >
        <v-tab-item v-if="isMobile"
                    id="livecontent"
                    class="pa-4"
                    :transition="false"
                    :reverse-transition="false"
                    :class="tabItemClasses"
        >
            <v-card class="end-content-text py-2 mx-2"
                    tile
                    elevation="0"
            >
                <qld-controller-toggle class="lower-center-of-video mt-4"
                                       data-qld-name="elearning-course-btn"
                >
                    <div class="elearning-button-container">
                        <v-btn class="elearning-button ml-auto mr-auto"
                               :class="{ mobile: isMobile}"
                               :href="navigateToElearningCourseButtonHrefProcessed"
                               target="_blank"
                        >
                            <span class="elearning-button-content">
                                {{ navigateToElearningCourseButtonTitle }}
                            </span>
                        </v-btn>
                    </div>
                </qld-controller-toggle>
                <div class="evaluation-button-container">
                    <evaluation-button v-if="!hideEvaluationButton"
                                       :blank="true"
                                       class="mt-4"
                    />
                </div>
                <custom-cta-button class="mt-4" />
                <div v-if="!hideEvaluationButton || isHandoutVisible || customCtaButtonEnabled" 
                     class="pt-4"
                />
                <div class="mt-4"
                     v-html="endPageContent"
                />
            </v-card>
        </v-tab-item>
        <v-tab-item v-if="isHandoutVisible && isMobile"
                    id="handout"
                    :transition="false"
                    :reverse-transition="false"
                    class="pt-4 pb-4"
                    :class="tabItemClasses"
        >
            <handout-button v-model="handouts"
                            class="end-page-handouts"
            />
        </v-tab-item>
        <v-tab-item v-if="isChatVisible"
                    id="chat"
                    :transition="false"
                    :reverse-transition="false"
                    class="pa-4"
                    :class="tabItemClasses"
                    :style="chatStyle"
        >
            <chat />
        </v-tab-item>
    </v-tabs-items>
</template>
<script>
    import
    {mapGetters, mapState} from 'vuex';
    import HandoutButton from '@/components/templates/default/EventPage/HandoutButton';
    import {tagSubtitution} from '@/services/ContentService';
    import Chat from '@/components/controls/Chat.vue';
    import EvaluationButton from '@/components/templates/default/EventPage/EvaluationButton.vue';
    import CustomCtaButton from '@/components/templates/default/EventPage/CustomCtaButton.vue';
    import mixin from '@/components/templates/default/EventPage/Mixins/searchElearningThirdPartyConnection.js';

    export default {
        name: 'TabContents',
        components: {
            CustomCtaButton,
            EvaluationButton,
            Chat,
            HandoutButton,
        },
        mixins: [mixin],
        props: {
            tab: {
                type: String,
                required: true,
            },
            mobileHeight: {
                type: Number,
                default: () => 0,
            }
        },
        computed: {
            ...mapGetters('project', [
                'currentEvent',
                'darkenPrimaryColor',
                'darkenPrimaryColorRGB',
                'darkenPrimaryColorStyle',
                'textColorStyle',
                'buttonShadowColorStyle',
                'privacyPolicyUrl',
                'getContent',
                'springerLook',
                'navigateToElearningCourseButtonTitle',
                'navigateToElearningCourseButtonHref',
                'customCtaButtonEnabled'
            ]),
            ...mapGetters('endPage', ['isHandoutVisible', 'isChatVisible']),
            ...mapState('project', ['project']),
            ...mapState('registration', ['registrationId', 'registrationData']),
            endPageContent() {
                return this.getContent(this.project.endPage.content);
            },
            hideEvaluationButton() {
                return this.project.evaluationButton.hide;
            },
            cssVars() {
                return {
                    '--r': this.darkenPrimaryColorRGB.r,
                    '--g': this.darkenPrimaryColorRGB.g,
                    '--b': this.darkenPrimaryColorRGB.b,
                    ...this.darkenPrimaryColorStyle,
                    ...this.textColorStyle,
                    ...this.buttonShadowColorStyle,
                };
            },
            isMobile() {
                return this.$vuetify.breakpoint.smAndDown;
            },
            tabItemClasses() {
                return {
                    'mt-4': !this.isMobile,
                    'mobile-content': this.isMobile,
                };
            },
            liveHandoutsText() {
                return tagSubtitution(
                    this.currentEvent.livePageHandoutText,
                    this.currentEvent,
                    this.registrationId,
                    this.project.eventTitle,
                    this.privacyPolicyUrl
                );
            },
            handouts() {
                return this.project.endPage.handouts;
            },
            chatStyle() {
                if (this.tab === 'chat' && this.isMobile) {
                    return {
                        height: (this.mobileHeight
                            - document.querySelector('.v-tabs').getBoundingClientRect().height) + 'px !important',
                    };
                }

                return {};
            }
        },
        mounted() {
            this.searchForElearningThirdPartyConnectionParams();
        }
    };
</script>

<style scoped lang="scss">
#chat {
    height: 95% !important;
}

.evaluation-button-container {
    text-align: center;
}

.end-page-side-bar {
    .elearning-button-container {
        text-align: center;
        .elearning-button.v-btn.v-btn--has-bg {
            background: var(--button-background);

            .elearning-button-content {
                color: var(--button-text-color);
            }
        }
    }
}
</style>
