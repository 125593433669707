import { $http } from '@/services/http';
import { registerForEvent } from '@/services/AgendaService';
import FakeRegistrationIDService from '@/services/FakeRegistrationIDService';
import { get } from 'lodash';
import router from '@/router';
import {getClientTimezone} from '@/services/TimeZoneService';

const registrationStore = {
    namespaced: true,
    state: {
        registrationId: null,
        registrationData: null,
        loading: false,
        prefill: {
            email: null,
            last_name: null,
            first_name: null
        },
        cannotRegisterForEvents: [],
    },
    getters: {
        registrationId: state => state.registrationId,
        isRegisteredForEvent: (state, getters) => (eventId) => {
            const selectedEvents = getters.registeredEvents;
            return selectedEvents.includes(eventId);
        },
        registeredEvents: (state) => state.registrationData?.SELECTED_EVENTS || [],
        isOnSiteVisitor: (state) => get(state, 'registrationData.on_site_viewer') === 1,
        isRegisteredForPwa: (state, getters, rootState, rootGetters) => {
            if (rootGetters['project/isHybrid']
                && rootGetters['project/registrationType'] === 'open'
                && (router.currentRoute.query.pwa || window.sessionStorage.getItem('pwaLook') === rootState['project'].project._id)) {
                window.sessionStorage.setItem('pwaLook', rootState['project'].project._id);
                return true;
            }

            return !!getters.registrationId
                && getters.isOnSiteVisitor
                && rootGetters['project/isHybrid'];
        },
        clientTimeZone: state => {
            if (state.registrationData?.time_zone) {
                return state.registrationData.time_zone;
            }
            return getClientTimezone();
        }
    },
    mutations: {
        setPrefill: (state, { email, firstName, lastName }) => {
            state.prefill.email = email;
            state.prefill.first_name = firstName;
            state.prefill.last_name = lastName;
        },
        setRegistrationLoading: (state, payload) => state.loading = payload,
        setRegistrationData: (state, payload) => state.registrationData = payload,
        setRegistrationId(state, payload) {
            state.registrationId = payload;
            window.sessionStorage.setItem('registration_id', payload);
        },
        setEventsBreakoutGroupToRegister: (state, payload) => state.eventsBreakoutGroupToRegister = payload,
        setCannotRegisterForEvents: (state, payload) => state.cannotRegisterForEvents = payload,
    },
    actions: {
        async sendRegistration(context, registrationData) {
            const response = await $http.post(process.env.VUE_APP_REGISTRATION_ENDPOINT, registrationData);
            const { registrationID, cannotRegisterForEvents } = response.data;
            return { registrationID, cannotRegisterForEvents };
        },
        async fetchElearningThirdPartyHash(context, data) {
            const response = await $http.post(process.env.VUE_APP_REQUEST_ELEARNING_3RD_PARTY_HASH, data);
            return response.data.data;
        },
        async registerForEvent(context, data) {
            const project = context.rootState.project.project;
            let registrationData = JSON.parse(JSON.stringify(context.state.registrationData));
            registrationData.SELECTED_EVENTS = registerForEvent(project, context.getters.registeredEvents, data.event);

            await $http.post(process.env.VUE_APP_REGISTRATION_UPDATE_ENDPOINT, {
                ...registrationData,
                event_id: context.rootGetters['project/currentEvent']._id,
            }).finally(() => {
                if (!data.isNavigating) {
                    context.commit('setRegistrationData', registrationData);
                }
            });
        },
        async updateAttendanceAndSendMail(context, data) {
            let registrationData = JSON.parse(JSON.stringify(context.state.registrationData));
            registrationData.on_site_viewer = data ? 1 : 0;

            await $http.post(process.env.VUE_APP_REGISTRATION_UPDATE_AND_MAIL_ENDPOINT, {
                ...registrationData,
                event_id: context.rootGetters['project/currentEvent']._id,
            }).finally(() => {
                context.commit('setRegistrationData', registrationData);
            });
        },
        async unregisterFromProject(context,) {
            await $http.put(process.env.VUE_APP_UNSUBSCRIBE_ENDPOINT
                .replace('{projectID}', context.rootState['project'].project._id)
                .replace('{registrationID}', context.state.registrationId));
        },
        async getRegistration({ commit, rootState }, registrationId) {
            if (registrationId) {
                commit('setRegistrationId', registrationId);
                const projectId = rootState.project.project?._id;
                if (projectId && FakeRegistrationIDService.looksToBeValidRegistrationID(registrationId)) {
                    const url = process.env.VUE_APP_REQUEST_REGISTRATION_DATA_ENDPOINT
                        .replace('{projectID}', projectId)
                        .replace('{registrationID}', registrationId);
                    const response = await $http.get(url);
                    commit('setRegistrationData', response.data);
                }
            }
        }
    }
};

export default registrationStore;
