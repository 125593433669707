<template>
    <div class="speakers-container">
        <hr class="speaker-divider">
        <h3 class="speaker-title mb-10">
            {{ $t('registration.speakers') }}
        </h3>
        <div class="d-flex justify-center flex-wrap gapped"
             :class="classes"
        >
            <div v-for="speaker in speakers"
                 :key="`speaker-${speaker.id}`"
                 class="text-center speaker-item"
                 :class="{'disable-link' : disableLink(speaker.link)}"
            >
                <a :href="speaker.link"
                   target="_blank"
                   class="speaker-link"
                >
                    <v-avatar size="150"
                              class="outlined"
                    >
                        <img v-if="speaker.avatar.url"
                             :src="speaker.avatar.url"
                             :alt="speaker.name"
                        >
                        <v-icon v-else>person</v-icon>
                    </v-avatar>
                </a>

                <h3 class="mt-3 mb-2">
                    <a :href="speaker.link"
                       target="_blank"
                       class="speaker-link"
                    >
                        <span>
                            {{ speaker.name }}
                        </span>
                    </a>
                </h3>

                <h6 class="mb-2">
                    {{ speaker.title }}
                </h6>
                <p class="summary">
                    {{ speaker.description }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';

    export default {
        name: 'Speakers',
        computed: {
            ...mapGetters('project', ['speakers', 'isInPreviewMode']),
            classes() {
                return [
                    `speakers-${this.speakers.length}`,
                ];
            }
        },
        methods: {
            disableLink(link) {
                return link === '' || this.isInPreviewMode ? true : false;
            }
        }
    };
</script>

<style lang="scss" scoped>

.disable-link {
    a{
        pointer-events: none;
    }
}
.speaker-divider {
    border-top: 1px solid #ffffff;
    margin-top: 100px;
    margin-bottom: 30px;
    opacity: 0.4
}

a {
    color: inherit !important;

    h3 {
        font-size: 22px !important
    }
}

p {
    font-size: 15px !important;
}

.speaker-title {
    font-size: 25px !important;
}

.speaker {
    max-width: 200px !important;
}

.summary {
    line-height: 110% !important;
}

.outlined {
    border: 2px solid white;
    border-radius: 50%;
}

.speaker-link {
    text-decoration: none;

    .v-icon {
        color: inherit;
        font-size: 6rem;
    }

    &:hover {
        span {
            text-decoration: underline;
        }
    }
}

@media screen and (min-width: 960px) {
    .registration-content .speakers-container {
        .gapped {
            gap: 40px;
        }

        .speaker-item {
            padding: 20px;
            box-sizing: border-box;
        }

        .speakers-1 {
            > div {
                flex: 0 0 calc(100% - 40px);
                width: calc(100% - 40px);
            }
        }

        .speakers-2,
        .speakers-3,
        .speakers-4,
        .speakers-5,
        .speakers-6,
        .speakers-7,
        .speakers-8,
        .speakers-9,
        .speakers-10 {
            > div {
                flex: 0 0 calc(50% - 40px);
                width: calc(50% - 40px);
            }
        }
    }
}

@media screen and (min-width: 1260px) {
    .registration-content .speakers-container {
        .gapped {
            gap: 40px;
        }

        .speaker-item {
            padding: 20px;
            box-sizing: border-box;
        }

        .speakers-1 {
            > div {
                flex: 0 0 calc(100% - 40px);
                width: calc(100% - 40px);
            }
        }

        .speakers-3,
        .speakers-5,
        .speakers-6,
        .speakers-9,
        .speakers-10 {
            > div {
                flex: 0 0 calc(33% - 40px);
                width: calc(33% - 40px);
            }
        }

        .speakers-2,
        .speakers-4,
        .speakers-7,
        .speakers-8 {
            > div {
                flex: 0 0 calc(50% - 40px);
                width: calc(50% - 40px);
            }
        }
    }
}

.v-dialog {
    .speaker-divider {
        border-top: 1px solid #3e3e3e;
        margin-top: 30px;
    }

    .outlined {
        border: 2px solid #3e3e3e;
    }
}

</style>
