<template>
    <count-down
        class="count-down-block"
        :class="{'mobile': isMobile, 'gradient': gradient}"
        :value="new Date(currentEvent.startTime)"
        :style="{...styles}"
    />
</template>

<script>
    import { mapGetters } from 'vuex';
    import CountDown from '@/components/controls/CountDown';

    export default {
        name: 'CountDownBlock',
        props: {
            gradient: {
                type: Boolean,
                default: () => false,
            }
        },
        components: {
            CountDown,
        },
        computed: {
            ...mapGetters('project', ['currentEvent', 'primaryColorRGB']),
            isMobile() {
                return this.$vuetify.breakpoint.smAndDown;
            },
            styles() {
                return {
                    '--r': this.primaryColorRGB.r,
                    '--g': this.primaryColorRGB.g,
                    '--b': this.primaryColorRGB.b,
                };
            }
        },
    };
</script>

<style lang="scss">
.count-down-block {
    padding-top: 40px;
    padding-bottom: 40px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;
    &.gradient {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), linear-gradient(334.74deg, rgba(51, 51, 51, 0.5) -18.63%, rgba(var(--r), var(--g), var(--b), 0.5) 88.23%);
    }

    h5{
        font-weight: 500 !important;
        font-size: 42px !important;
        line-height: 120% !important;
        margin-bottom: 40px !important;
        color: var(--block-text-color) !important;
    }

    .expiration-message {
        color: #fff;
    }

    .status-message{
        color: var(--block-text-color) !important;
        text-align: center;
        justify-content: space-around;
        width:100%;
    }

    .countdown{
        display: flex;
        align-items: center;
    }

    .countdown-outer {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0;
        gap: 40px;
        width: 715px;
        height: 165px;

        .countdown-item {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0;

            .value {
                font-style: normal;
                font-weight: 400 !important;
                font-size: 106px !important;
                line-height: 120% !important;
                color: var(--block-text-color) !important;
            }

            .text {
                font-style: normal;
                font-weight: 400 !important;
                font-size: 32px !important;
                line-height: 120% !important;
                color: var(--block-text-color);
                text-align: center;
                flex: none;
                order: 1;
                flex-grow: 0;
                opacity: 0.8 !important;
            }
        }

        .countdown-separator {
            position: static;
            opacity: 0.6 !important;
            border: 2px solid var(--block-text-color);
            flex: none;
            flex-grow: 0;
            width: 0;
            min-height: 100px !important;
            max-height: 100px !important;
            align-self: center !important;
            margin-top: 10px;
        }
    }

    &.mobile{
        min-height: 220px !important;

        h5{
            font-size: 21px !important;
            margin-bottom: 12px !important;
        }
        .countdown-outer{
            width: 280px;
            height: 60px;
            gap: 9.5px;
            margin: 0;
            .countdown-item{
                height: 60px !important;
                .value{
                    width: 44px !important;
                    height: 60px !important;
                    font-size: 39px !important;
                }

                .text{
                    font-size: 11px !important;
                }
            }

            .countdown-separator {
                min-height: 35px !important;
                max-height: 35px !important;
                opacity: 0.4 !important;
                border: 0.5px solid var(--block-text-color) !important;
                margin: 0 0 10px 0 !important
            }
        }
    }
}

.half-width{
    .count-down-block {
        display: flex;
        justify-content: center;
        flex-direction: column;
        position: relative;
        &.gradient {
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), linear-gradient(334.74deg, rgba(51, 51, 51, 0.5) -18.63%, rgba(var(--r), var(--g), var(--b), 0.5) 88.23%);
        }

        .status-message{
            color: var(--block-text-color) !important;
        }

        h5{
            font-weight: 500 !important;
            font-size: 42px !important;
            line-height: 120% !important;
            margin-bottom: 24px !important;
            color: var(--block-text-color) !important;
            @media (max-width: 1366px) and (min-width: 960px) {
                font-size: 40px !important;
            }
        }

        .countdown-outer {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 0;
            gap: 19px;

            width: 560px;
            height: 122px;

            @media (max-width: 1366px) and (min-width: 960px) {
                width: 500px;
                height: 122px;
                gap: 10px;
            }

            .countdown-item {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0;
                height: 122px;

                .value {
                    font-style: normal;
                    font-weight: 400 !important;
                    font-size: 78px !important;
                    line-height: 120% !important;
                    height: auto;
                    color: var(--block-text-color) !important;
                    @media (max-width: 1366px) and (min-width: 960px) {
                        font-size: 68px !important
                    }
                }

                .text {
                    font-style: normal;
                    font-weight: 400 !important;
                    font-size: 23px !important;
                    line-height: 120% !important;
                    color: var(--block-text-color);
                    text-align: center;
                    flex: none;
                    order: 1;
                    flex-grow: 0;
                    opacity: 0.8 !important;
                    @media (max-width: 1366px) and (min-width: 960px) {
                        font-size: 22px !important
                    }
                }
            }

            .countdown-separator {
                position: static;
                opacity: 0.4 !important;
                border: 1px solid var(--block-text-color);
                flex: none;
                flex-grow: 0;
                width: 0;
                min-height: 70px !important;
                max-height: 70px !important;
                align-self: center !important;
                margin: 0 !important
            }
        }

        &.mobile{
            min-height: 220px !important;

            h5{
                font-size: 21px !important;
                margin-bottom: 12px !important;
            }
            .countdown-outer{
                width: 280px;
                height: 60px;
                gap: 9.5px;
                margin: 0;
                .countdown-item{
                    height: 60px !important;
                    .value{
                        width: 44px !important;
                        height: 60px !important;
                        font-size: 39px !important;
                    }

                    .text{
                        font-size: 11px !important;
                    }
                }

                .countdown-separator {
                    min-height: 35px !important;
                    max-height: 35px !important;
                    opacity: 0.4 !important;
                    border: 0.5px solid var(--block-text-color) !important;
                    margin: 0 0 10px 0 !important
                }
            }
        }
    }
}

</style>
