<template>
    <v-dialog max-width="1094"
              :value="true"
              scrollable
              @input="goToRegistration"
    >
        <v-card class="rounded-0 modal-container rc"
                :class="classes"
                :style="styles"
        >
            <v-card-text class="confirmation-card-content">
                <v-icon color="white"
                        class="mail-sent-icon"
                >
                    mdi-email-check
                </v-icon>
                <h5 class="confirmation-title">
                    {{ $t('registration.confirmation_title') }}
                </h5>
                <div v-if="cannotRegisterForEvents.length > 0"
                     class="confirmation-custom-text mb-4"
                >
                    <span>{{ $t('registration.cannot_register_start') }}</span>
                    <span v-for="(event,index) in cannotRegisterForEvents"
                          :key="`${event+index}`"
                    >
                        <b>{{ getEventName(event) }}</b>{{ `${(cannotRegisterForEvents.length - 1) === index ? '. ' : ', '}` }}
                    </span>
                    <span>
                        {{ cannotRegisterForEvents.length > 1 ?
                            $t('registration.cannot_register_end_plural') :
                            $t('registration.cannot_register_end_singular')
                        }}
                    </span>
                </div>
                <div class="confirmation-custom-text mb-7"
                     v-html="registrationConfirmation"
                />
                <h2 v-if="!hideCalendarInvite"
                    class="font-weight-regular mb-2 confirmation-calendar-title"
                >
                    <v-icon class="mr-1">
                        mdi-calendar-check
                    </v-icon>
                    {{ $t('registration.add_to_calendar') }}
                </h2>
                <div v-if="!hideCalendarInvite"
                     class="calendar-link-wrapper mb-8"
                >
                    <a :href="icsCalendar"
                       class="link-with-divider"
                       target="_blank"
                       :download="eventTitleSlug"
                    >
                        iCal
                    </a>
                    <a :href="outlookCalendar"
                       class="link-with-divider"
                       target="_blank"
                    >
                        Outlook.com
                    </a>
                    <a :href="googleCalendar"
                       target="_blank"
                    >
                        Google
                    </a>
                </div>
                <div class="confirmation-custom-text mb-4">
                    {{ watchText }}
                </div>
                <v-btn :color="darkenPrimaryColor"
                       :href="personalUrl"
                       target="_blank"
                       elevation="2"
                       class="join-button"
                >
                    {{ watchButtonText }}
                </v-btn>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
    import {mapGetters, mapState} from 'vuex';
    import {
        ROUTE_REGISTRATION,
        ROUTE_PERSONAL_LINK,
    } from '@/router/constants';
    import {google, ics, office365} from 'calendar-link';
    import {snakeCase} from 'lodash';

    export default {
        name: 'RegistrationConfirmation',
        computed: {
            ...mapState('project', ['project']),
            ...mapState('registration', ['registrationId']),
            ...mapGetters('registration', ['isOnSiteVisitor']),
            ...mapState('registration', ['cannotRegisterForEvents', 'registrationData']),
            ...mapGetters('project', [
                'registrationConfirmationText',
                'onSiteRegistrationConfirmationText',
                'currentEvent',
                'getContent',
                'isMultiSession',
                'isInPreviewMode',
                'registrationConfirmationPreviewMode',
                'hideCalendarInvite',
                'darkenPrimaryColor',
                'darkenPrimaryColorStyle',
                'textColorStyle',
                'fontFamilyStyle',
                'buttonBackgroundStyle',
                'buttonBorderRadiusStyle',
            ]),
            eventTitleSlug() {
                return snakeCase(this.project.eventTitle);
            },
            styles(){
                return {
                    ...this.darkenPrimaryColorStyle, 
                    ...this.textColorStyle, 
                    ...this.fontFamilyStyle, 
                    ...this.buttonBackgroundStyle, 
                    ...this.buttonBorderRadiusStyle
                };
            },
            eventCalendar() {
                const calendarObject = {
                    title: this.project.calendarTitle ? this.project.calendarTitle : this.project.eventTitle,
                    description: this.$t('calendar.cta_before_link') + ': ' + this.personalUrl,
                    start: new Date(this.project.events[0].startTime * 1000),
                    end: new Date(this.project.events[this.project.events.length - 1].startTime * 1000 + this.project.events[this.project.events.length - 1].durationInMinutes * 60000),
                };

                if(this.isOnSiteVisitor ){
                    calendarObject['location'] = this.project.location.formattedAddress;
                }

                return calendarObject;
            },
            icsCalendar(){
                return ics(this.eventCalendar);
            },
            icsFilename() {
                const toSnakeCase = (str) =>
                    str &&
                    str
                        .match(
                            /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
                        )
                        .map((x) => x.toLowerCase())
                        .join('_');

                return `${toSnakeCase(this.project.calendarTitle ? this.project.calendarTitle : this.project.eventTitle)}.ics`;
            },
            googleCalendar() {
                return google(this.eventCalendar);
            },
            outlookCalendar() {
                let calendar = {...this.eventCalendar};
                calendar['description'] = this.$t('calendar.cta_before_link') + ': ' + `<a href="${this.personalUrl}">${this.personalUrl}</a>`;
                return office365(calendar);
            },
            isMobile() {
                return this.$vuetify.breakpoint.smAndDown;
            },
            classes() {
                return {
                    'mobile': this.isMobile,
                    'preview' : this.isInPreviewMode,
                };
            },
            registrationConfirmation(){
                if(this.project.projectType === 'hybrid' && (this.isOnSiteVisitor || this.previewModeIsOnSite)) {
                    return this.getContent(this.onSiteRegistrationConfirmationText);
                }
                return this.getContent(this.registrationConfirmationText);
            },
            watchText() {
                if(this.project.projectType === 'hybrid' && (this.isOnSiteVisitor || this.previewModeIsOnSite)) {
                    return this.$t('registration.pwa_access');
                }
                return this.isMultiSession ? this.$t('registration.multisession_access') : this.$t('registration.simplesession_access');
            },
            watchButtonText() {
                if(this.project.projectType === 'hybrid' && (this.isOnSiteVisitor || this.previewModeIsOnSite)) {
                    return this.$t('registration.watch_pwa');
                }
                return this.$t('registration.watch');
            },
            personalUrl(){
                return window.location.origin + this.$router.resolve({
                    name: ROUTE_PERSONAL_LINK,
                    params: {
                        event_id: this.currentEvent._id,
                        registration_id: this.registrationId
                    }
                }).href;

            },
            previewModeIsOnSite() {
                return this.isInPreviewMode && this.registrationConfirmationPreviewMode;
            }
        },
        methods: {
            goToRegistration() {
                this.$router.push({
                    name: ROUTE_REGISTRATION,
                    params: {
                        event_id: this.currentEvent._id
                    }
                });
            },
            getEventName(id){
                let name;
                this.project.events.map((event) => {
                    if(event._id === id){
                        name = event.name;
                    }
                });
                return name;
            }
        }
    };
</script>

<style lang="scss">
.rc {
    font-family: var(--font-family);
    text-align: center;
    overflow: hidden !important;
    position: relative;

    .v-btn{
        border-radius: var(--button-border-radius) !important;
        background: var(--button-background)
    }

    &::before{
      position: absolute;
      top: 0;
      left: 0;
      content: '';
      width: 100%;
      height: 50%;
      background: var(--darken-primary-color) !important;
      clip-path: polygon(50% 50%, 100% 0%, 0% 0%);
    }

    .confirmation-card-content{
      padding: 40px 60px 40px !important;
    }

    .mail-sent-icon{
        font-size: 60px !important;
        margin-bottom: 30px;
        margin-left: 4px;
    }

    .preview{
        a{
            pointer-events: none;
        }
    }


    .calendar-link-wrapper{
        display: inline-flex;
        a{
            color: var(--darken-primary-color) !important;
        }
        .link-with-divider{
            border-right: 1.5px solid var(--darken-primary-color);
            margin-right: 10px;
            padding-right: 10px;
        }
    }


    .calendar-button.theme--light.v-btn {
        color: white !important;
    }

    .calendar-button {
        font-family: var(--font-family);
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        padding: 0 10px !important;
        .v-btn__content {
            color: var(--text-color);
        }
    }

    .join-button.theme--light.v-btn {
        color: white !important;
    }

    .join-button {
        font-family: var(--font-family);
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 150%;
        margin-bottom: 20px;
        height: 44px;
        padding: 10px 10px;
        .v-btn__content {
            color: var(--text-color);
        }
    }

    .confirmation-event {
        color: #3E3E3E;
        margin-bottom: 4px;
        font-family: var(--font-family);
        font-style: normal;
        font-weight: 500;
        font-size: 42px;
        line-height: 120%;
    }

    .confirmation-custom-text {
        color: #3E3E3E;
        font-family: var(--font-family);
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 150%;

        li {
            list-style-position: inside;
        }

        a {
            color: var(--darken-primary-color) !important;
        }
    }

    .confirmation-title {
        margin-top: 24px;
        margin-bottom: 28px;
        font-family: var(--font-family);
        font-style: normal;
        font-weight: 500;
        font-size: 23px;
        line-height: 120%;
        color: var(--darken-primary-color)
    }

    .confirmation-time {
        margin-bottom: 24px;
        color: #3E3E3E;
        font-family: var(--font-family);
        font-style: normal;
        font-weight: 500;
        font-size: 19px;
        line-height: 120%;
    }

    .confirmation-time:first-letter {
        text-transform: uppercase;
    }

    .confirmation-link {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0;
        font-family: var(--font-family);
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 120%;
        margin: 4px 0;
    }

    .unique-link-label {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        font-family: var(--font-family);
        font-style: italic;
        font-weight: normal;
        font-size: 14px;
        line-height: 120%;
    }


    .unique-link {
        margin-bottom: 4px;
        max-width: 100%;
        color: var(--darken-primary-color) !important;
    }

    .confirmation-calendar-title {
        color: #3E3E3E;
        i {
            color: #3E3E3E;
        }
    }

    &.mobile {

        &::before{
          clip-path: polygon(50% 35%, 102% -1%, 0% 0%);
        }

        .confirmation-card-content{
          padding: 0 !important;
        }

        &.modal-container {
            padding: 20px;
        }

        .mail-sent-icon{
          font-size: 50px !important;
        }

        .confirmation-custom-text{
          font-size: 14px;
        }

        .close-button {
            z-index: 100;
            position: absolute;
            right: 5px;
            top: 10px;
        }

        .confirmation-title {
            font-size: 20px;
            margin-top: 0;
        }

        .confirmation-event {
            font-size: 25px;
        }

        .confirmation-link {
            padding: 0 10px;
            font-size: 12px;
            label, a {
                font-size: 12px;
            }
        }

        .calendar-wrapper {
            display: block;
            font-size: 12px;
        }

        .registration-confirmation-agenda {
            margin: 0;
        }
    }

}


</style>
