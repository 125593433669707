<template>
    <v-app :class="{'iframed': isInIframe, 'pwa': isRegisteredForPwa}">
        <script v-if="projectLoaded"
                type="application/javascript"
        >
            {{ script }}
        </script>
        <MainLoader v-if="loading" />
        <v-layout
            v-if="showPage"
            column
            class="template-default project-layout"
            :class="{'pwa': isRegisteredForPwa}"
            :style="styles"
        >
            <router-view class="header-component"
                         name="header"
            />
            <pwa-default-body v-if="isRegisteredForPwa">
                <router-view name="body" />
            </pwa-default-body>
            <default-body v-else>
                <router-view name="body" />
            </default-body>
            <router-view name="footer" />
            <privacy-policy-modal v-if="!isRegisteredForPwa"
                                  :open="showPrivacyPolicy"
            />
            <support-modal v-if="!isRegisteredForPwa"
                           :open="showSupportModal"
            />
            <snackbar />
        </v-layout>

        <v-layout v-if="showNotFound"
                  column
        >
            <not-found />
        </v-layout>
    </v-app>
</template>

<script>
    import {ROUTE_ONDEMAND_PAGE, ROUTE_ONDEMAND_WITH_REGISTRATION_PAGE} from '@/router/constants';
    import {mapActions, mapGetters, mapState} from 'vuex';
    import DefaultBody from '@/components/templates/default/DefaultBody';
    import PwaDefaultBody from '@/pwa/templates/default/DefaultBody';
    import NotFound from '@/components/layouts/NotFound';
    import MainLoader from '@/components/controls/MainLoader';
    import PrivacyPolicyModal from '@/components/controls/PrivacyPolicyModal';
    import SupportModal from '@/components/controls/SupportModal';
    import Snackbar from '@/components/controls/Snackbar';
    import {isInIframe} from '@/services/Integration';
    import {getClientTimezone} from '@/services/TimeZoneService';
    import {replaceFavicon} from '@/services/FaviconService';

    export default {
        components: {
            NotFound,
            MainLoader,
            DefaultBody,
            PwaDefaultBody,
            PrivacyPolicyModal,
            SupportModal,
            Snackbar,
        },
        created() {
            this.$vuetify.theme.themes.light.primary = '#2977BE';
            this.$vuetify.theme.themes.light.primarydark = '#2B4390';
            this.$vuetify.theme.themes.light.primarylight = '#31B6BB';
            this.$vuetify.theme.themes.light.secondary = '#961E87';
            this.$vuetify.theme.themes.light.secondarydark = '#4F3480';
            this.$vuetify.theme.themes.light.secondarylight = '#C20C7F';
            this.$vuetify.theme.themes.light.accent = '#2977BE';
            this.$vuetify.theme.themes.light.error = '#EE3A23';
            this.$vuetify.theme.themes.light.info = '#23A6DF';
            this.$vuetify.theme.themes.light.success = '#40B649';
            this.$vuetify.theme.themes.light.warning = '#F7A41D';
            this.$vuetify.theme.themes.light.gray1 = '#EEEEEE';
            this.$vuetify.theme.themes.light.medgray1 = '#BBBBBB';
            this.$vuetify.theme.themes.light.medgray2 = '#888888';
            this.$vuetify.theme.themes.light.darkgray = '#454545';
        },
        data() {
            return {
                eventLoading: true,
                registrationLoading: true,
            };
        },
        computed: {
            ...mapGetters('project', [
                'pwaPrimaryColor',
                'primaryColor',
                'hasOnDemand',
                'springerLook',
                'fontFamilyStyle',
                'isHybrid',
                'buttonBorderRadiusStyle',
                'buttonBackgroundStyle',
                'labelBorderRadiusStyle',
                'cardBorderRadiusStyle',
                'inputBorderRadiusStyle',
                'requiresRegistrationForOnDemand',
                'isInPreviewMode',
                'faviconUrl',
            ]),
            ...mapState('project', ['project']),
            ...mapGetters('registration', ['isRegisteredForPwa', 'clientTimeZone']),
            loading() {
                return this.eventLoading || this.registrationLoading;
            },
            showPage() {
                return !this.loading && this.project;
            },
            showNotFound() {
                return !this.loading && !this.project;
            },
            showPrivacyPolicy() {
                return !this.$route.meta.avoidRender && this.$route.hash === '#privacy-policy';
            },
            styles() {
                return {
                    backgroundColor: this.isRegisteredForPwa ? this.pwaPrimaryColor : this.primaryColor,
                    ...this.fontFamilyStyle,
                    ...this.buttonBackgroundStyle,
                    ...this.buttonBorderRadiusStyle,
                    ...this.labelBorderRadiusStyle,
                    ...this.cardBorderRadiusStyle,
                    ...this.inputBorderRadiusStyle,
                };
            },
            showSupportModal() {
                return !this.$route.meta.avoidRender && this.$route.hash === '#support';
            },
            isInIframe,
            projectLoaded() {
                return !!this.project;
            },
            script() {
                return `window.executeComponent = function(component, componentName) { ${this.project?.customScript ?? '' } }`;
            },
            style() {
                return this.project?.customStyle ?? '';
            }
        },
        methods: {
            ...mapActions('project', ['getProject', 'getDefaultProjectByCompanyId']),
            ...mapActions('registration', ['getRegistration']),
            goToOndemand() {
                if (this.isInPreviewMode) {
                    return;
                }

                if (!this.requiresRegistrationForOnDemand) {
                    this.$router.push({name: ROUTE_ONDEMAND_PAGE, hash: this.$route.hash});
                } else {
                    if (this.$route.name === ROUTE_ONDEMAND_WITH_REGISTRATION_PAGE) {
                        return;
                    }
                    this.$router.push({name: ROUTE_ONDEMAND_WITH_REGISTRATION_PAGE, hash: this.$route.hash, params: {
                        registration_id: this.$route.params.registration_id,
                        event_id: this.$route.params.event_id,
                    }});
                }
            },
            createCustomStyle() {
                if (this.style) {
                    const styleElement = document.createElement('style');
                    styleElement.setAttribute('type', 'text/css');
                    styleElement.setAttribute('id', 'custom-style');
                    if (styleElement.stylesheet) {
                        styleElement.stylesheet.cssText = this.style;
                    } else {
                        styleElement.appendChild(document.createTextNode(this.style));
                    }
                    (document.head || document.getElementsByTagName('head')[0]).appendChild(styleElement);
                }
            },
            async loadProject() {
                if (this.$route.params.company_id) {
                    await this.getDefaultProjectByCompanyId(this.$route.params.company_id);
                    this.eventLoading = false;
                } else if (this.$route.params.event_id) {
                    await this.getProject(this.$route.params.event_id);
                    this.eventLoading = false;
                } else if (window.sessionStorage.getItem('event_id')) {
                    await this.getProject(window.sessionStorage.getItem('event_id'));
                    this.eventLoading = false;
                }
            },
        },
        watch: {
            '$route.params.event_id': {
                handler: async function (event_id) {
                    this.eventLoading = true;
                    await this.getProject(event_id);
                    this.eventLoading = false;
                }
            },
            '$route.params.registration_id': {
                handler: async function(registration_id) {
                    this.registrationLoading = true;
                    await this.getRegistration(registration_id);
                    this.registrationLoading = false;
                }
            },
        },
        async mounted() {
            try {
                await this.loadProject();

                this.createCustomStyle();

                await this.getRegistration(this.$route.params.registration_id);

                // edge case (if for any reason, the registration time zone
                // doesn't match the browser time zone, reload the project
                // with the registration time zone)
                if (getClientTimezone() !== this.clientTimeZone) {
                    await this.loadProject();
                }

                this.registrationLoading = false;

                if (this.hasOnDemand && !this.$route.meta.avoidRedirectingToOndemand) {
                    this.goToOndemand();
                }
            } finally {
                if (this.faviconUrl) {
                    replaceFavicon(this.faviconUrl);
                }
                this.eventLoading = false;
                this.registrationLoading = false;
            }

            if(!this.springerLook){
                document.querySelector('html').className = 'default-template';
            }

            document.querySelector('title').innerText = this.project.eventTitle
                + ' - '
                + this.project.companyName;
        },
    };
</script>

<style scoped>
.header-component {
  position: sticky;
  top: 0;
  z-index: 2;
}
</style>
<style lang="scss">

.default-template{
    scrollbar-color: #201f1f4b transparent !important;
    scrollbar-width: thin;

    ::-webkit-scrollbar{
        width: 8px;
        background: transparent;
    }

    ::-webkit-scrollbar-thumb{
        -webkit-border-radius: 10px;
        border-radius: 10px;
        background: #0d0e0dc9;
    }
}

.preview {
  form {
    button[type=submit]{
        pointer-events: none;
    }
    .cta-button {
      pointer-events: none;
    }
  }
  .qld-poll{
      input[type=radio],  label {
        pointer-events: none;
    }
  }
  .embedded-chat{
    pointer-events: none;
  }
  .evaluation-button{
    pointer-events: none;
  }
  .custom-cta {
      pointer-events: none;
  }
}
</style>
