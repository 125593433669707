<template>
    <div>
        <div class="program-title">
            {{ $t('agenda.program') }}
        </div>
        <div v-for="(topic, index) in topics"
             :key="`topic-${index}`" 
             class="d-flex py-6 topic"
             :class="{'with-border': index!== topics.length-1}"
        >
            <div class="interval-wrapper d-flex align-center justify-center flex-column">
                <label
                    class="time-badge"
                    :style="{...darkenPrimaryColorStyle,...textColorStyle}"
                    :class="{'ended': ended(topic)}"
                >
                    {{ timeUntilText(topic) }}
                </label>
            </div>
            <div class="content">
                <span class="topic-title mb-2 font-weight-bold">
                    {{ topic.title }}
                </span>
                <p class="description">
                    {{ topic.description }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapState } from 'vuex';
    import { timeUntilReactive, timeUntilToString} from '@/services/AgendaService';

    export default {
        name: 'ProgramElement',
        computed:{
            ...mapState('time', ['currentTime']),
            ...mapGetters('project', [
                'textColorStyle',
                'darkenPrimaryColorStyle',
                'topics',
            ]),
            isMobile() {
                return this.$vuetify.breakpoint.smAndDown;
            },
        },
        methods: {
            endTime(topic) {
                return (
                    topic.startTime + (60 * topic.durationInMinutes)
                );
            },
            ended(topic) {
                return this.endTime(topic) <= this.currentTime;
            },
            now(topic) {
                return (
                    topic.startTime <= this.currentTime && this.currentTime < this.endTime(topic)
                );            
            },
            timeUntilText(topic) {
                if (this.ended(topic)) {
                    return this.$t('multiSession.ended');
                } else if (this.now(topic)) {
                    return this.$t('multiSession.now');
                } else {
                    return timeUntilToString(timeUntilReactive(topic.startTime, this.currentTime));
                }
            },
        }
    };
</script>

<style lang="scss" scoped>

.program-title{
    color: #3e3e3e;
    font-size: 21px;
    font-weight: 500;
}

.topic{
    min-width: 100%;

    .interval-wrapper{
        margin-right: 20px;
        .interval{
            font-size: 14px;
            color: #ffff;
            &.no-badge{
                font-size: 16px;
            }
        }


    }
    .time-badge {
        font-size: 13px;
        line-height: 24px;
        font-weight: 900;
        padding: 0 12px;
        height: 24px;
        border-radius: 20px;
        min-width: 60px;
        background: var(--darken-primary-color);
        color: var(--text-color);
        display: flex;
        justify-content: center;

        &.ended{
            color: #3e3e3e
        }
    }
    .content{
        flex: 0 1 70%;
        max-width: 70%;
        min-width: 70%;
        padding-right: 10px;
        .topic-title{
            font-size: 21px;
            color: #3e3e3e;
        }

        .description{
            font-size: 16px !important;
            color: #3e3e3e;
        }
    }

    &.with-border{
        border-bottom: 1px solid #0000001f;
    }
}

</style>