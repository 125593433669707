
const fonts = {
    'archivo': '"Archivo", arial, helvetica, sans-serif',
    'open-sans': '"Open Sans", arial, helvetica, sans-serif',
    'roboto': '"Roboto", arial, helvetica, sans-serif',
    'lato': '"Lato", arial, helvetica, sans-serif',
    'montserrat': '"Montserrat", arial, helvetica, sans-serif',
    'oswald': '"Oswald", arial, helvetica, sans-serif',
    'source-sans-pro': '"Source Sans Pro", arial, helvetica, sans-serif',
    'slabo': '"Slabo 27px", arial, helvetica, sans-serif',
    'raleway': '"Raleway", arial, helvetica, sans-serif',
    'pt-sans': '"PT Sans", arial, helvetica, sans-serif',
    'merriweather': '"Merriweather", arial, helvetica, sans-serif',
    'arial': 'arial, helvetica, sans-serif',
    'GalanoGrotesque': '"GalanoGrotesque", arial, helvetica, sans-serif',
    'Caros': '"Caros", arial, helvetica, sans-serif',
    'MyriadForRabobank': '"MyriadForRabobank", arial, helvetica, sans-serif',
    'BebasNeue': '"BebasNeue", arial, helvetica, sans-serif',
};

export const getFont = (font) => {
    return fonts[font];
};