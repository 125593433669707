<template>
    <v-row class="mx-0 splash-page"
           :class="classes"
           justify="center"
    >
        <v-col class="content-col-splash mx-0"
               cols="12"
               lg="12"
        >
            <div v-if="showDefaultSplashPage"
                 class="content-splash-wrapper-helper"
            >
                <h5 class="webinar-start-date">
                    <start-time />
                </h5>
                <div class="splash-content-wrapper">
                    <h1 class="event-title-splash">
                        {{ project.eventTitle }}
                    </h1>
                    <div class="content-text-splash"
                         v-html="splashPageContent"
                    />
                </div>
                <v-row class="mt-10 start-time-row"
                       justify="center"
                >
                    <count-down :value="new Date(currentEvent.startTime)"
                                class="count-down"
                                @starting="updateTimerLabel"
                    />
                </v-row>
                <agenda v-if="isMultiSession"
                        class="mt-8 agenda-splash"
                        :show-status="agendaShowsStatus"
                        collapse-breakouts
                />
            </div>
            <dynamic-splash v-else />
            <default-footer class="end-footer" />
        </v-col>
    </v-row>
</template>

<script>
    import {mapGetters, mapState} from 'vuex';
    import CountDown from '@/components/controls/CountDown';
    import StartTime from '@/components/controls/StartTime';
    import DefaultFooter from '@/components/templates/default/DefaultFooter';
    import Agenda from '@/components/controls/Agenda/Agenda';
    import {hidesAgendaButtons} from '@/services/AgendaService';
    import DynamicSplash from '@/components/templates/default/EventPage/LiveComponents/DynamicSplash.vue';
    import trackUserLinkClicks from '@/components/templates/default/EventPage/Mixins/trackUserLinkClicks';

    export default {
        name: 'Splash',
        components: {DynamicSplash, DefaultFooter, CountDown, StartTime, Agenda},
        mixins: [trackUserLinkClicks],
        data() {
            return {
                showTimerLabel: true,
            };
        },
        computed: {
            ...mapGetters('project', ['currentEvent','getContent', 'isMultiSession']),
            ...mapState('project', ['project']),
            isMobile() {
                return this.$vuetify.breakpoint.smAndDown;
            },
            classes() {
                return {
                    'mobile': this.isMobile,
                    'multi-session': this.isMultiSession,
                };
            },
            splashPageContent(){
                return this.getContent(this.project.splashPageContent);
            },
            agendaShowsStatus() {
                return !hidesAgendaButtons(this.project._id);
            },
            showDefaultSplashPage() {
                if (this.$route.query.previewMode === 'default-waiting-page') {
                    return true;
                }

                if (this.$route.query.previewMode === 'dynamic-waiting-page') {
                    return false;
                }

                return this.project.waitingPageDynamicBlocks.layout === 'basic';
            }
        },
        methods: {
            updateTimerLabel(value) {
                if (!this.showTimerLabel && value) {
                    return false;
                }
                this.showTimerLabel = value;
            }
        }
    };
</script>

<style lang="scss">
.splash-page {
    .count-down{
        display: flex;
        flex-direction: row;
        gap: 48px
    }
    
    .link-to-test-page{
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 150%;
        color: white;
    }
    .webinar-start-date {
        font-style: normal;
        font-weight: 500;
        font-size: 23px;
        line-height: 120%;
        margin-bottom: 16px;
    }

    .event-title-splash {
        font-style: normal;
        font-weight: normal;
        font-size: 57px;
        line-height: 120%;
        margin-bottom: 16px;
    }
    .current-event-title{
        margin-bottom: 10px;
    }

    .splash-content-wrapper {
        margin-bottom: 50px;
    }

    .content-text-splash {
        color: white;
        p {
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 150%;
        }
        li {
            list-style-position: inside;
        }
    }

    .content-text-splash:hover {
        overflow-y: auto;
    }

    .webinar-start-title {
        position: static;
        width: auto;
        height: 28px;
        left: 0;
        top: 25px;
        margin-right: 32px;
        @media (max-width: 1366px) and (min-width: 961px) {
            font-size: 20px !important
        }
    }

    .content-col-splash {
        align-items: center;
        justify-content: center;
        text-align: center;
        height: calc(100vh - 64px);
        overflow-y: auto;
        overflow-x: hidden;
    }


    .content-splash-wrapper-helper {
        margin: 0 10vw;
        padding-top: 7vh;
        padding-bottom: 5vh;

        .agenda-splash{
            padding-left: 8vw;
            padding-right: 8vw;
            color: #ffffff;
            i, circle{
                color: #ffffff !important;
            }
        }
    }

    .start-time-row {
        color: #ffffff;
    }

    .end-footer {
        left: 1rem;
        bottom: 0;
        width: calc(100vw - 2rem)
    }

    &.mobile {
        .content-col-splash {
            padding: 0;
        }

        .content-text-splash {
            max-height: 30vh;
            overflow: auto;
        }

        .webinar-start-date {
            font-size: 19px;
            margin-bottom: 0;
        }

        .event-title-splash {
            font-size: 33px;
        }

        .current-event-title{
            font-size: 24px !important;
        }

        .webinar-start-title {
            font-size: 19px;
            margin-bottom: 8px;
        }

        .splash-content-wrapper {
            margin-bottom: 0;
        }

        .content-splash-wrapper-helper {
            margin: 121px 19.5px 48px 19.5px;
            padding: 0;
            text-align: left;

            .agenda-splash {
                padding-left: 0;
                padding-right: 0;
            }
        }

        .start-time-row {
            display: block;
            padding: 0 19.5px 0 19.5px;
        }
    }
    &.mobile.multi-session{
        .content-splash-wrapper-helper {
            margin-top: 24px;
        }

    }
}
</style>
