<template>
    <v-row v-if="!loading"
           class="fullHeight reg-page ma-0"
           :class="{'preview' : isInPreviewMode, 'iframed': isInIframe}"
           :style="{...darkenPrimaryColorStyle, ...textColorStyle, ...rgbStyleVars}"
    >
        <default-header v-if="isMobile"
                        class="registration-mobile-header"
        />
        <v-col v-if="!isMobile"
               cols="12"
               lg="8"
               md="8"
               sm="0"
               class="content-col pa-0 registration-content"
               :style="style"
        >
            <div :class="gradientClass"
                 class="gradient-div"
            >
                <div class="pa-10 content-wrapper">
                    <div class="content">
                        <h5 class="event-start-time"
                            :class="{'mb-4' : !address}"
                        >
                            <start-time />
                        </h5>
                        <h5 v-if="address"
                            class="location"
                        >
                            {{ address }}
                        </h5>
                        <h1 class="event-title">
                            {{ project.eventTitle }}
                        </h1>
                        <div class="content-text mb-10"
                             v-html="registrationIntroduction"
                        />
                    </div>
                    <count-down :value="new Date(currentEvent.startTime)"
                                class="count-down white--text"
                                @starting="updateTimerLabel"
                    />
                    <agenda v-if="isMultiSession"
                            hide-breakouts
                            class="mt-8 agenda-light-text"
                    />
                    <program-single-session v-if="topics.length > 0"
                                            class="program-single-session mt-15"
                    />
                    <speakers v-if="hasSpeakers" />
                </div>
            </div>
        </v-col>
        <v-spacer />
        <v-col class="form-holder ma-0 pa-0"
               cols="12"
               lg="4"
               md="4"
               sm="12"
               :class="{'col-md-12': isMobile }"
        >
            <v-card class="rounded-0 form-card">
                <div v-if="!isMobile">
                    <img class="logo"
                         :src="logo"
                         alt="Logo"
                         :style="customLogoStyle"
                    >
                </div>
                <div v-if="isMobile">
                    <div class="grey--text"
                         :style="{'font-size': '14px'}"
                    >
                        <start-time />
                    </div>
                    <h1 :style="{color: darkenPrimaryColor}"
                        class="form-event-title"
                        @click="openDialog"
                    >
                        {{ project.eventTitle }}
                        <v-icon :color="darkenPrimaryColor">
                            mdi-information
                        </v-icon>
                    </h1>
                </div>
                <h2 class="form-title">
                    {{ $t('registration.title') }}
                </h2>
                <linked-in />
                <control-holder>
                    <v-form :key="updateCount"
                            ref="form"
                            class="registration-form"
                            @submit.prevent="register"
                    >
                        <div v-for="field in registrationFields"
                             :key="field.id"
                        >
                            <label class="form-label">
                                {{ field.label }} 
                                <span v-if="isMobilePhoneField(field)"
                                      class="mobile-input-text"
                                >
                                    ({{ $t('fields.mobile_text') }})
                                </span>
                            </label>
                            <component :is="getComponent(field)"
                                       v-model="fieldValues[field.name]"
                                       :label="field.label"
                                       :name="`register-${field.name}`"
                                       :class="`register-${field.name}`"
                                       :required="field.required"
                                       :options="field.options"
                                       :color="darkenPrimaryColor"
                                       single
                                       background-color="white"
                                       solo
                                       flat
                                       dense
                            />
                        </div>
                        <multi-session-event-fields v-if="isMultiSession"
                                                    v-model="selectedEvents"
                                                    :required="true"
                        />
                        <div v-if="showCustomPrivacyPolicy"
                             class="privacy-policy-remark font-non-italic mb-4"
                        >
                            <b>Privacy policy</b>
                            <br>
                            <br>
                            By reserving your place for this webinar you give consent to us to share your personal information with Viatris.
                            <br>
                            <br>
                            Please review <a href="https://www.springermedicine.com/springermedicine-com/privacy-policy/23614432"
                                             target="_blank"
                            >our privacy policy</a> and <a href="https://www.viatris.com/en/viatris-privacy-notice"
                                                           target="_blank"
                            >Viatris' privacy policy</a> for more information.
                            You can withdraw consent at any time by directly reaching out to the sponsor via dataprivacy@viatris.com.
                        </div>
                        <div v-if="!showCustomPrivacyPolicy"
                             class="privacy-policy-remark mb-4"
                        >
                            {{ $t('registration.accept_terms_1') }}
                            <a v-if="hasPrivacyPolicy"
                               :href="hasPrivacyPolicy"
                               target="_blank"
                            >{{ $t('registration.accept_terms_2') }}</a>
                            <a v-if="!hasPrivacyPolicy"
                               href="#privacy-policy"
                            >{{ $t('registration.accept_terms_2') }}</a>
                            {{ $t('registration.accept_terms_3') }}
                        </div>
                        <v-btn
                            type="submit"
                            :color="darkenPrimaryColor"
                            :loading="registrationLoading"
                            :disabled="registrationLoading"
                            class="submit-button"
                        >
                            {{ $t('registration.submit') }}
                        </v-btn>
                    </v-form>
                </control-holder>
            </v-card>
        </v-col>
        <v-dialog
            v-model="showDialog"
            scrollable
        >
            <v-card light
                    class="dialog-card rounded-0"
                    :style="{...mobileStyle}"
            >
                <div class="close-button">
                    <v-btn
                        icon
                        @click="showDialog = false"
                    >
                        <v-icon color="white">
                            mdi-close
                        </v-icon>
                    </v-btn>
                </div>
                <v-row class="dialog-card-row">
                    <v-col cols="12">
                        <div class="dialog-content-wrapper">
                            <p class="event-start-time-dialog"
                               :class="{'mb-2': !address}"
                            >
                                <start-time />
                            </p>
                            <p v-if="address"
                               class="location-dialog mt-0"
                            >
                                {{ address }}
                            </p>
                            <h3 class="dialog-event-title"
                                :style="rgbStyleVars"
                            >
                                {{ project.eventTitle }}
                            </h3>
                            <div class="dialog-description"
                                 v-html="registrationIntroduction"
                            />
                        </div>
                        <count-down v-if="currentEvent"
                                    class="webinar-countdown-dialog"
                                    :value="new Date(currentEvent.startTime)"
                                    @starting="updateTimerLabel"
                        />
                        <agenda v-if="isMultiSession"
                                class="mt-8 white--text"
                        />
                        <speakers v-if="hasSpeakers" />
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>
    </v-row>
    <div v-else>
        {{ $t('registration.loading') }}
    </div>
</template>

<script>
    import {mapGetters, mapState, mapActions} from 'vuex';
    import FieldEmail from '@/components/controls/fields/FieldEmail';
    import FieldText from '@/components/controls/fields/FieldText';
    import FieldDatePicker from '@/components/controls/fields/FieldDatePicker';
    import ControlHolder from '@/components/templates/default/ControlHolder';
    import FieldRadio from '@/components/controls/fields/FieldRadio';
    import FieldCheckbox from '@/components/controls/fields/FieldCheckbox';
    import FieldDropdown from '@/components/controls/fields/FieldDropdown';
    import FieldTextarea from '@/components/controls/fields/FieldTextarea';
    import FieldMobile from '@/components/controls/fields/FieldMobile';
    import FieldOnSiteViewer from '@/components/controls/fields/FieldOnSiteViewer';
    import {getInitialFieldValues} from '@/services/FieldService';
    import CountDown from '@/components/controls/CountDown';
    import StartTime from '@/components/controls/StartTime';
    import DefaultHeader from '@/components/templates/default/DefaultHeader';
    import Agenda from '@/components/controls/Agenda/Agenda';
    import MultiSessionEventFields from '@/components/controls/fields/MultiSessionEventFields';
    import ProgramSingleSession from '@/components/controls/ProgramSingleSession';
    import Speakers from '@/components/pages/RegistrationPage/Speakers';
    import { get } from 'lodash';
    import LinkedIn from '@/components/controls/LinkedIn';
    import {isInIframe} from '@/services/Integration';
    import SpringerCasService from '@/services/SpringerCasService';
    import { ROUTE_REGISTRATION } from '@/router/constants';
    import trackUserLinkClicks from '@/components/templates/default/EventPage/Mixins/trackUserLinkClicks';

    export default {
        name: 'RegistrationForm',
        components: {
            LinkedIn,
            ControlHolder,
            FieldText,
            FieldDatePicker,
            FieldEmail,
            FieldTextarea,
            FieldDropdown,
            FieldCheckbox,
            FieldRadio,
            FieldOnSiteViewer,
            StartTime,
            CountDown,
            DefaultHeader,
            FieldMobile,
            Agenda,
            MultiSessionEventFields,
            Speakers,
            ProgramSingleSession
        },
        mixins: [trackUserLinkClicks],
        data() {
            return {
                showDialog: false,
                fieldValues: {},
                selectedEvents: [],
                loading: false,
                updateCount: 0,
                showTimerLabel: true,
                showCustomPrivacyPolicy: false,
            };
        },
        computed: {
            ...mapState('project', ['project']),
            ...mapState('registration', {registrationLoading: 'loading'}),
            ...mapState('registration', ['prefill']),
            ...mapGetters('project', [
                'registrationIntroductionText',
                'registrationFields', 
                'currentEvent', 
                'logo',
                'backgroundImage', 
                'getContent', 
                'isMultiSession',
                'isInPreviewMode', 
                'backgroundGradient',
                'speakers',
                'darkenPrimaryColor',
                'darkenPrimaryColorStyle',
                'textColorStyle', 
                'primaryColorRGB', 
                'topics',
                'fontFamilyStyle',
                'springerOAuthClientId',
                'springerLook',
            ]),
            isInIframe,
            isMobile() {
                return this.$vuetify.breakpoint.smAndDown;
            },
            rgbStyleVars() {
                return {
                    '--r': this.primaryColorRGB.r,
                    '--g': this.primaryColorRGB.g,
                    '--b': this.primaryColorRGB.b,
                };
            },
            style() {
                return {
                    'background-image': `url(${this.backgroundImage})`,
                    ...this.fontFamilyStyle
                };
            },
            mobileStyle() {
                return {
                    ...this.fontFamilyStyle,
                    ...this.darkenPrimaryColorStyle,
                };
            },
            registrationIntroduction(){
                return this.getContent(this.registrationIntroductionText);
            },
            hasPrivacyPolicy() {
                return this.project.privacyPolicyUrl;
            },
            customLogoStyle() {
                const style = {};
                if(this.project.style.customLogoHeightRegistrationPage) {
                    style.height = this.project.style.customLogoHeightRegistrationPage + 'px';
                }
                if(this.project.style.customLogoWidthInPercentRegistrationPage) {
                    style.width = `${this.project.style.customLogoWidthInPercentRegistrationPage}%`;
                    style.height = 'auto';
                }

                return style;
            },
            gradientClass() {
                return {
                    'has-gradient': this.backgroundGradient,
                };
            },
            hasSpeakers() {
                return this.speakers.length > 0;
            },
            address(){
                return this.project.location.formattedAddress;
            },
        },
        methods: {
            ...mapActions('project', ['getRegistrationsCountPerEvent']),
            getComponent(field) {
                return `field-${field.type}`;
            },
            isMobilePhoneField(field) {
                return field.type === 'mobile';
            },
            register() {
                if (this.$refs.form.validate()) {
                    this.$emit('submit', {
                        fieldValues: this.fieldValues,
                        selectedEvents: this.selectedEvents,
                    });
                }
            },
            getFieldValues() {
                const fields = {};
                this.registrationFields.forEach(field => {
                    fields[field.name] = getInitialFieldValues(field);
                    const prefill = get(this.prefill, field.name);
                    if (prefill) {
                        fields[field.name] = prefill;
                    }
                });
                this.fieldValues = fields;
                ++this.updateCount;
            },
            updateTimerLabel(value) {
                if (!this.showTimerLabel && value) {
                    return false;
                }
                this.showTimerLabel = value;
            },
            openDialog() {
                this.showDialog = true;
            },
            checkForSpringerSmedcomParams() {
                if ( !this.springerLook) {
                    return;
                }
                let springer = new SpringerCasService(this.$route.params.event_id, this.springerOAuthClientId);

                if ( !springer.isSpringerInternationalSmedcom() ) {
                    return;
                }
                this.showCustomPrivacyPolicy = true;

                if ( !this.$route.query.smd && this.$route.name === ROUTE_REGISTRATION ) {
                    this.$router.push({
                        name: 'SpringerOAuthPage',
                        params: {
                            event_id: this.$route.params.event_id,
                        },
                    });
                    return;
                }
                let springerFormParams = JSON.parse(atob(this.$route.query.smd));
                let allowedKeys = ['first_name', 'email', 'last_name', 'big_number', 'cas_id'];
                for (let i = 0; i < allowedKeys.length; i++ ) {
                    let key = allowedKeys[i];
                    this.fieldValues[key] = springerFormParams[key];
                }
            },
        },
        async mounted() {
            this.loading = true;
            this.getFieldValues();

            if (this.isMultiSession) {
                await this.getRegistrationsCountPerEvent();
            }
            this.loading = false;
            this.checkForSpringerSmedcomParams();

            await this.$nextTick();
            if(this.$route.query.linkedin) {
                this.register();
            }
        }
    };
</script>

<style scoped lang="scss">
.count-down{
    display: flex;
    flex-direction: row;
    gap: 48px
}
.preview{
    a{
        pointer-events: none;
    }
}

.content-wrapper {
    min-width: 60vw;
    z-index: 3;
}

.register-mail.v-input.v-input__control.v-input__slot.v-text-field__slot.v_label.theme--light {
    font-size: 100px
}

.submit-button {
    height: 44px !important;
    width: 133px !important;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.2px;
    padding: 10px 32px;
    box-shadow: 0 8px 16px rgba(var(--r), var(--g), var(--b), 0.3);
    color: var(--text-color);
}

.registration-mobile-header {
    position: sticky;
    top: 0;
    z-index: 1;
    min-width: 100%;
}

.close-button {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
}

.form-holder {
    background-color: white;
    flex-direction: column;
    justify-content: space-around;

    input, textarea, select {
        border: 1px solid rgba(62, 62, 62, 0.7);
        box-sizing: border-box;
        border-radius: 2px;
    }

    @media (max-width: 960px) {
        height: calc(100vh - 63px);
    }
}

.fullHeight {
    height: 100%;
}

.event-start-time {
    position: static;
    left: 0;
    top: 0;

    flex: none;
    order: 0;
    flex-grow: 0;
    @media (max-width: 1366px) and (min-width: 961px) {
        font-size: 16px
    }
}

.event-start-time:first-letter {
    text-transform: uppercase;
}

.location{
    position: static;
    left: 0;
    top: 0;

    flex: none;
    order: 0;
    flex-grow: 0;
    @media (max-width: 1366px) and (min-width: 961px) {
        font-size: 16px
    }
}

.event-title {
    position: static;
    left: 0;
    top: 44px;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 16px 0;
    @media (max-width: 1366px) and (min-width: 961px) {
        font-size: 40px
    }
}

.content-text {
    position: static;
    left: 0;
    top: 212px;
    overflow: hidden;
    color: white;
}

.content-text:hover {
    overflow-y: auto;
}

.webinar-start-title {
    position: static;
    width: 250px;
    height: 28px;
    left: 0;
    top: 25px;
    margin-right: 32px;
    @media (max-width: 1366px) and (min-width: 961px) {
        font-size: 18px !important
    }
}

.content-col {
    background-position: center center;
    background-size: cover;
    height: 100vh;
    position: fixed;

    .gradient-div {
        align-items: center;
        justify-content: space-around;
        display: grid;
        overflow: auto;
        width: 100%;
        height: 100%;
        z-index: 0;

        background: rgba(var(--r), var(--g), var(--b), 0.4);

        &.has-gradient{
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
                    linear-gradient(134.24deg,   rgba(0, 0, 0, 0.5) 22.26%,rgba(var(--r), var(--g), var(--b), 0.5) 65.06%) !important;
        }
    }
}

.dialog-card {
    font-family: var(--font-family);
    overflow: hidden;
    &:before {
        position: absolute;
        left: 0;
        top: 0;
        content: "";
        width: 100%;
        height: 100%;
        display: block;
        z-index: 1;
        background: rgba(var(--r), var(--g), var(--b), 0.4);
    }

    &.has-gradient:before{
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), linear-gradient(314.24deg, rgba(0, 0, 0, 0.5) 22.26%, rgba(var(--r), var(--g), var(--b), 0.5) 65.06%) !important;
        transform: rotate(180deg);
    }

    div {
        z-index: 2;
    }

    max-height: 80vh;
    display: flex;
    flex-direction: column;
    padding: 20px 20px 30px;
    @media (max-height: 600px), (max-width: 300px) {
        padding: 10px 9px 15px
    }

    .dialog-card-row{
        overflow: auto;
    }
}

.dialog-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
}

.event-start-time-dialog {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 120%;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.location-dialog{
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 120%;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    margin-bottom: 8px !important;
}

.dialog-event-title {
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    margin-bottom: 16px;
    font-style: normal;
    font-weight: 500;
    font-size: 33px;
    line-height: 120%;
    @media (max-height: 780px) {
        font-size: 29px;
    }
    @media (max-height: 650px) {
        font-size: 25px;
    }
    @media (max-height: 620px), (max-width: 300px) {
        font-size: 22px;
    }
    color: var(--darken-primary-color);
}

.dialog-description {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    max-height: 28vh;
    overflow: auto;
}

.dialog-content-wrapper {
    margin-bottom: 30px;
}

.webinar-start-title-dialog {
    font-style: normal;
    font-weight: 500;
    font-size: 19px;
    line-height: 120%;
    @media (max-height: 780px) {
        font-size: 16px;
    }
    @media (max-height: 620px), (max-width: 300px) {
        font-size: 14px;
    }
}

.form-card {
    min-height: 100vh;
    padding: 40px 60px;
    @media (max-width: 960px) {
        padding: 40px 20px 77px !important;
    }
}

.form-title {
    position: static;
    width: 154px;
    height: 50px;
    left: 0;
    top: 72px;
    font-size: 42px !important;
    display: flex;
    align-items: center;

    color: #3E3E3E !important;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin-top: 24px;
    margin-bottom: 24px;

    @media (max-width: 960px) {
        font-size: 38px;
        margin-top: 20px
    }
}

.form-event-title {
    font-size: 19px !important;
    align-items: center;
}

.form-label {
    flex: none;
    flex-grow: 0;
    margin: 4px 0;
}

.terms-checkbox {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0;
    flex: none;
    flex-grow: 0;
    margin-top: 24px;
}

.logo {
    position: static;
    height: 44px;
    left: 0;
    top: 0;

    flex: none;
    order: 0;
    flex-grow: 0;
    margin-bottom: 32px;
}

.webinar-countdown-dialog {
    @-moz-document url-prefix() {
        margin-left: 20px;
    }
}

</style>
<style lang="scss">
.template-default{
    .iframed {
        .form-card {
            padding-top: 10px !important;
            padding-bottom: 10px !important;
        }

        .form-title {
            font-size: 22px !important;
            margin-top: 0;
            margin-bottom: 0;
        }
    }
}

.registration-content .speakers-container {
    color: var(--text-color);
}

.privacy-policy-remark{
    font-style: italic;
    font-weight: normal;
    font-size: 14px;
    line-height: 120%;

    a{
        color:  var(--darken-primary-color) !important;
    }
}

.content-text {
    color: white !important;
}

.agenda-light-text {
    color: #ffffff;
}

.registration-form {
    .v-text-field.v-text-field--enclosed .v-text-field__details {
        margin-bottom: 0 !important
    }

    .v-input__slot{
        margin-bottom: 4px !important;
    }
    .row.field-checkbox {
        margin-top: initial;
        margin-bottom: 0.2rem;
        .col {
            min-width: 100%;
        }
    }
    .field-radio {
        .v-radio {
            width: 100%;
            margin: 0.3rem 0;
        }
    }
}

.content-wrapper{
    .program-single-session{
        .topic{
            .interval-wrapper{
                margin-right: 20px;
            }
        }
    }
}

.privacy-policy-remark.font-non-italic {
    font-style: normal;
}
</style>
