<template>
    <footer :class="{'mobile': isMobile}">
        <div class="menu">
            <router-link 
                :to="routeWithPrivacyPolicy"
            >
                {{ $t('privacyPolicy.title') }}
            </router-link>
        </div>
    </footer>
</template>

<script>
    import { mapGetters } from 'vuex';

    export default {
        name: 'DynamicPageFooter',
        data() {
            return {
                year: new Date().getFullYear()
            };
        },
        computed: {
            ...mapGetters('project', ['logo']),
            routeWithPrivacyPolicy() {
                return {
                    name: this.$route.name,
                    params: this.$route.params,
                    query: this.$route.query,
                    hash: '#privacy-policy',
                };
            },
            isMobile() {
                return this.$vuetify.breakpoint.smAndDown;
            },
        },
        methods:{
            scrollToTop(){
                window.scrollTo(0, 0);
            },
        }
    };
</script>

<style scoped lang="scss">
footer {
    height: 60px;
    background-color: #ffffff;
    padding: 15px 60px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .company{
        font-size: 14px;
        line-height: 21px;
        display: inline-flex;
        align-items: center;    
        color: rgba(62, 62, 62, 0.7);
    }

    .menu{
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0;
        width: 240px;
        justify-content: end;
        a{
            text-decoration: none;
            color: #3E3E3E;
            font-size: 16px;
            line-height: 150%;
            font-weight: 400;
        }
    }

    img{
        max-height: 29.87px ;
    }

    &.mobile{
        flex-direction: column;
        align-items: center;
        padding: 16px 0;
        gap: 20px;

        img{
            order: 0;
        }

        .menu{
            order: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 0;
            a{
                font-size: 14px;
            }
        }

        .company{
            order: 2;
            font-size: 10px;
        }
    }
}
</style>
