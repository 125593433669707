<template>
    <div :style="styles">
        <span class="single-session-title text-uppercase"> {{ $t('agenda.program') }}</span>
        <div v-for="(topic, index) in topics"
             :key="`topic-${index}`" 
             class="d-flex py-6 topic"
             :class="{'mobile':isMobile,
                      'event-page': isDynamicBlocksFlavourAgenda,
                      'default-registration' :showDefaultRegistrationPage
             }"
        >
            <div class="interval-wrapper d-flex align-center justify-center flex-column"
                 :class="{'mb-1': isMobile}"
            >
                <span v-if="!isDynamicBlocksFlavourAgenda"
                      class="interval"
                      :class="{'mr-3': isMobile, 'mb-1': !isMobile}"
                >
                    {{ topic.formattedStartEndTime }}
                </span>
                <label
                    class="time-badge"
                    :style="{...darkenPrimaryColorStyle,...textColorStyle, ...badgeStyle}"
                    :class="{'ended': ended(topic) && !isDynamicBlocksFlavourAgenda}"
                >
                    {{ isDynamicBlocksFlavourAgenda ? topic.formattedStartEndTime : timeUntilText(topic) }}
                </label>
            </div>
            <div class="content"
                 :class="{'pl-4': !isMobile}"
            >
                <h5 class="topic-title mb-2 font-weight-bold">
                    {{ topic.title }}
                </h5>
                <label
                    v-if="isDynamicBlocksFlavourAgenda"
                    class="time-badge"
                    :style="{...darkenPrimaryColorStyle,...textColorStyle, ...badgeStyle}"
                >
                    {{ topic.formattedStartEndTime }}
                </label>
                <p class="description">
                    {{ topic.description }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapState } from 'vuex';
    import { timeUntilReactive, timeUntilToString} from '@/services/AgendaService';

    export default {
        name: 'ProgramSingleSession',
        computed:{
            ...mapState('time', ['currentTime']),
            ...mapState('project', ['project']),
            ...mapGetters('project', [
                'textColorStyle',
                'darkenPrimaryColorStyle',
                'topics',
                'isDynamicBlocksFlavourAgenda',
            ]),
            isMobile() {
                return this.$vuetify.breakpoint.smAndDown;
            },
            showDefaultRegistrationPage() {
                return this.project.dynamicBlocks.layout === 'basic' || this.$route.query.previewMode === 'default-registration';
            },
            styles(){
                return {
                    ...this.labelBorderRadiusStyle
                };
            },
            badgeStyle() {
                return {
                    'color': 'var(--text-color)',
                };
            }
        },
        methods: {
            endTime(topic) {
                return (
                    topic.startTime + (60 * topic.durationInMinutes)
                );
            },
            ended(topic) {
                return this.endTime(topic) <= this.currentTime;
            },
            now(topic) {
                return (
                    topic.startTime <= this.currentTime && this.currentTime < this.endTime(topic)
                );            
            },
            timeUntilText(topic) {
                if (this.ended(topic)) {
                    return this.$t('multiSession.ended');
                } else if (this.now(topic)) {
                    return this.$t('multiSession.now');
                } else {
                    return timeUntilToString(timeUntilReactive(topic.startTime, this.currentTime));
                }
            },
        }
    };
</script>

<style lang="scss" scoped>

.single-session-title{
    color: #ffffff !important;
}

.topic{
    min-width: 100%;
    border-bottom: 1px solid #0000001f;
    padding: 21px 0 21px !important;

    &.default-registration{
        padding: 21px 0 21px !important;
    }

    &.event-page{
        border-bottom: 1px solid #ffffff1f;
        padding: 3px 0 21px;
        .interval-wrapper{
            .time-badge{
                font-size: 16px;
                min-width: 170px;
            }
        }
        .description{
            font-size: 18px !important;
        }
        .content{
            .time-badge{
                display: none;
            }
        }

        &.mobile{
            .interval-wrapper{
               display: none;
               .time-badge{
                    display: none;
               }
            }
            .content{
                .topic-title{
                    font-size: 16px !important;
                }
                .description{
                    font-size: 14px;
                }
                .time-badge {
                    margin: 4px 0;
                    max-width: 170px;
                    display: block;
                }
            }
        }
    }
    .interval-wrapper{
        justify-content: initial !important;
        margin-right: 20px;
        .interval{
            font-size: 14px;
            color: #ffff;
            &.no-badge{
                font-size: 16px;
            }
        }


    }
    .time-badge {
        font-size: 13px;
        line-height: 24px;
        font-weight: 900;
        padding: 0 12px;
        height: 24px;
        border-radius: var(--label-border-radius);
        min-width: 60px;
        background: var(--button-background);
        color: var(--text-color);
        display: flex;
        justify-content: center;

        &.ended{
            color: #3e3e3e
        }
    }
    .content{
        flex: 0 1 70%;
        max-width: 70%;
        min-width: 70%;
        .topic-title{
            font-size: 23px;
        }

        .time-badge{
            display: none !important;
        }
    }

    &.mobile{
        flex-direction: column !important;
        .interval-wrapper{
            flex: 0 1 100% !important;
            max-width: 100% !important;
            min-width: 100% !important;
            justify-content: flex-start !important;
            flex-direction: initial !important;
        }
        .content{
            flex: 0 1 100%;
            max-width: 100%;
            min-width: 100%;
            .time-badge{
                display: flex !important;
            }
        }
    }
}

</style>