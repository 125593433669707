<template>
    <v-tabs-items v-model="tab"
                  :style="cssVars"
                  touchless
    >
        <v-tab-item id="livecontent"
                    class="pa-4"
                    :transition="false"
                    :reverse-transition="false"
                    :class="tabItemClasses"
        >
            <v-card class="live-content-text py-2 mx-2"
                    tile
                    elevation="0"
            >
                <div v-html="livePageContent" class="mt-4" />
            </v-card>
        </v-tab-item>
        <v-tab-item id="handout"
                    :transition="false"
                    :reverse-transition="false"
                    class="pt-4 pb-4"
                    :class="tabItemClasses"
        >
            <v-card elevation="0"
                    tile
                    class="py-2 content-card mb-10 mx-2"
            >
                <div class="handout-text"
                     v-html="liveHandoutsText"
                />
            </v-card>
            <div class="handout-buttons">
                <handout-button v-model="handouts" />
            </div>
        </v-tab-item>
        <v-tab-item
            id="agenda"
            :transition="false"
            :reverse-transition="false"
            class="mobile-agenda py-8"
        >
            <agenda
                class="px-7 live-sidebar-agenda"
                force-mobile-view
                :show-status="agendaShowsStatus"
                hide-title
                collapse-breakouts
            />
        </v-tab-item>
        <v-tab-item
            id="speakers"
            :transition="false"
            :reverse-transition="false"
            class="sidebar-speakers"
            :class="tabItemClasses"
        >
            <speakers />
        </v-tab-item>
        <v-tab-item
            id="program"
            :transition="false"
            :reverse-transition="false"
            class="program-single-session pa-4"
        >
            <program-single-session with-badge />
        </v-tab-item>
        <v-tab-item
            id="question"
            :transition="false"
            :reverse-transition="false"
            class="pa-4"
            :class="tabItemClasses"
        >
            <question class="mx-2" />
        </v-tab-item>

        <v-tab-item
            id="poll"
            :transition="false"
            :reverse-transition="false"
            class="pa-4"
            :class="tabItemClasses"
        >
            <v-card v-if="!active"
                    elevation="0"
                    tile
                    class="content-card"
            >
                <div class="no-poll-message">
                    {{ $t("event.no_poll") }}
                </div>
            </v-card>
            <poll />
        </v-tab-item>
        <v-tab-item
            id="chat"
            :transition="false"
            :reverse-transition="false"
            class="pa-4"
            :class="tabItemClasses"
            :style="chatStyle"
        >
            <chat />
        </v-tab-item>
        <v-tab-item
            id="quiz"
            :transition="false"
            :reverse-transition="false"
            class="pa-4"
            :class="tabItemClasses"
        >
            <v-card v-if="!activeQuestion"
                    elevation="0"
                    tile
                    class="content-card"
            >
                <div class="no-poll-message">
                    {{ $t("event.no_quiz") }}
                </div>
            </v-card>
            <quiz />
        </v-tab-item>
        <v-tab-item
            id="custom"
            :transition="false"
            :reverse-transition="false"
            class="pa-4"
            :class="tabItemClasses"
        >
            <v-card elevation="0"
                    tile
                    class="content-card py-2 mx-2"
            >
                <div class="customTabContent"
                     v-html="liveCustomTabBodyText"
                />
            </v-card>
        </v-tab-item>
    </v-tabs-items>
</template>
<script>
    import {mapGetters, mapState} from 'vuex';
    import Agenda from '@/components/controls/Agenda/Agenda';
    import HandoutButton from '@/components/templates/default/EventPage/HandoutButton';
    import ProgramSingleSession from '@/components/controls/ProgramSingleSession';
    import Poll from '@/components/templates/default/EventPage/LiveComponents/Poll';
    import Question from '@/components/templates/default/EventPage/LiveComponents/Question';
    import Quiz from '@/components/templates/default/EventPage/LiveComponents/Quiz';
    import {tagSubtitution} from '@/services/ContentService';
    import {hidesAgendaButtons} from '@/services/AgendaService';
    import Chat from '@/components/controls/Chat.vue';
    import Speakers from '@/pwa/controls/Speakers.vue';

    export default {
        name: 'TabContents',
        components: {
            Speakers,
            Chat,
            HandoutButton,
            Agenda,
            ProgramSingleSession,
            Poll,
            Question,
            Quiz
        },
        props: {
            tab: {
                type: String,
                required: true,
            },
            mobileHeight: {
                type: Number,
                default: () => 0,
            }
        },
        computed: {
            ...mapGetters('project', [
                'currentEvent', 
                'customTabBodyText',
                'darkenPrimaryColor',
                'darkenPrimaryColorRGB',
                'darkenPrimaryColorStyle', 
                'textColorStyle', 
                'buttonShadowColorStyle',
                'privacyPolicyUrl',
                'getContent',
            ]),
            ...mapGetters('qld-vuex-store/poll', ['active']),
            ...mapGetters('qld-vuex-store/quiz', ['activeQuestion']),
            ...mapState('project', ['project']),
            ...mapState('registration', ['registrationId', 'registrationData']),
            livePageContent(){
                return this.getContent(this.currentEvent.livePageContent);
            },
            cssVars() {
                return {
                    '--qld-inline-poll-background': 'rgba(255, 255, 255, 0.9)',
                    '--qld-inline-quiz-background': 'rgba(255, 255, 255, 0.9)',
                    '--r': this.darkenPrimaryColorRGB.r,
                    '--g': this.darkenPrimaryColorRGB.g,
                    '--b': this.darkenPrimaryColorRGB.b,
                    ...this.darkenPrimaryColorStyle,
                    ...this.textColorStyle,
                    ...this.buttonShadowColorStyle,
                };
            },
            isMobile() {
                return this.$vuetify.breakpoint.smAndDown;
            },
            tabItemClasses() {
                return {
                    'mt-4': !this.isMobile,
                    'mobile-content': this.isMobile,
                };
            },
            liveHandoutsText() {
                return tagSubtitution(
                    this.currentEvent.livePageHandoutText,
                    this.currentEvent,
                    this.registrationId,
                    this.project.eventTitle,
                    this.privacyPolicyUrl
                );
            },
            liveCustomTabBodyText(){
                return tagSubtitution(
                    this.customTabBodyText,
                    this.currentEvent,
                    this.registrationId,
                    this.project.eventTitle,
                    this.privacyPolicyUrl
                );
            },
            handouts() {
                return this.currentEvent.handouts;
            },
            agendaShowsStatus() {
                return !hidesAgendaButtons(this.project._id);
            },
            chatStyle() {
                if (this.tab === 'chat' && this.isMobile) {
                    return {
                        height: (this.mobileHeight
                            - document.querySelector('.v-tabs').getBoundingClientRect().height) + 'px !important',
                    };
                }

                return {};
            }
        },
        watch: {
            tab(v) {
                // iPhone hack
                if (v === 'speakers' && this.isMobile) {
                    setTimeout(() => {
                        document.querySelector('.sidebar-speakers').parentNode.scrollTop = 0;
                    }, 200);
                }
            }
        }
    };
</script>

<style scoped lang="scss">
#chat {
    height: 100% !important;
}

div#chat.mobile-content {
    height: var(--mobile-sidebar-height) !important;
    min-height: var(--mobile-sidebar-height) !important;
}
</style>

<style lang="scss">
.sidebar-speakers {
    div.speakers {
        color: #3e3e3e;

        .speaker-title {
            display: none;
        }

        .speakers-wrapper {
            gap: 0;
            div.speaker {
                gap: 0;
                padding: 20px !important;
                .speaker-main-information-wrapper {
                    gap: 20px;
                    .speaker-avatar-link {
                        .v-avatar {
                            max-width: 80px !important;
                            max-height: 80px !important;
                            min-width: 80px !important;
                            min-height: 80px !important;
                            img {
                                max-width: 80px !important;
                                max-height: 80px !important;
                                min-width: 80px !important;
                                min-height: 80px !important;
                            }
                        }
                    }
                }
                .speaker-summary {
                    padding-top: 10px;
                    color: #3e3e3e;
                }
            }
        }
    }
}
</style>